import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import ClaimToken from '../abis/ClaimToken.json'

import Navbar from './Navbar'
import Main from './Main'

// this is the token address
const deployedContractAddress = '0x053Cd2f375Ab25Eb60b27ce63b14E26B6533ab9D';

class App extends Component {

  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    console.log(accounts)
    this.setState({ account: accounts[0] })

    const claimContract = web3.eth.Contract(ClaimToken.abi, deployedContractAddress);
    this.setState({ claimContract })

    for (var i = 1; i <= 1; i++) {
      let claimAmount = await claimContract.methods.getClaimAmount(accounts[0]).call();
      if (typeof claimAmount === 'undefined' || claimAmount === null) {
        claimAmount = 0
      }
      this.setState({
        products: [...this.state.products, {'amount': claimAmount}]
      })
    }
  this.setState({ loading: false})

  }


  constructor(props) {
    super(props)
    this.state = {
      account: '',
      products: [],
      loading: true
    }

    this.executeClaimToken = this.executeClaimToken.bind(this)
  }

  executeClaimToken() {
    this.setState({ loading: true })
    this.state.claimContract.methods.claim().send({ from: this.state.account})  
    .once('transactionHash', (receipt) => {
      this.setState({ loading: false })
      window.alert('The transaction has been submitted. Please check the transaction details in Etherscan')
    })
  }

  render() {
    return (
      <div>
        <Navbar account={this.state.account} />
        <div className="container-fluid mt-5">
          <div className="row">
            <main role="main" className="col-lg-12 d-flex">
              { this.state.loading
                ? <div id="loader" className="text-center"><p className="text-center">Loading...</p></div>
                : <Main
                  products={this.state.products}
                  executeClaimToken={this.executeClaimToken} />
              }
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
